<template>
  <div>
    <h2 style="margin-top:16px">
      {{ titulo }}
    </h2>
    <div :id="'canvas-barchart-container' + chartIdNumber"></div>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: {
    chartIdNumber: {
      type: Number,
      default: 0
    },
    labels: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    titulo: { type: String, default: "" },
    dataLabel: { type: String, default: "" },
    dataValues: {
      type: Array,
      default: () => []
    },
    backgroundColor: { type: String, default: "" }
  },

  watch: {
    labels() {
      this.updateData();
    },
    dataValues() {
      this.updateData();
    }
  },
  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      //const ctx = document.getElementById("BarChart" + this.titulo);
      new Chart(this.resetCanvas(), {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.dataLabel,
              backgroundColor: this.backgroundColor,
              data: this.dataValues
            }
          ]
        },
        options: {
          responsive: false,
          showTooltips: true,
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: true
          },
          hover: {
            mode: "nearest",
            intersect: true
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  suggestedMin: 0,
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: this.label
                }
              }
            ]
          }
        }
      });
    },
    resetCanvas() {
      let container = document.getElementById(
        "canvas-barchart-container" + this.chartIdNumber
      );
      let canvas = document.createElement("canvas");
      canvas.style.width = "100%";
      canvas.style.height = "50vh";
      canvas.id = `BarChart ${this.titulo}`;
      container.innerHTML = "";
      container.append(canvas);
      return canvas;
    }
  }
};
</script>
